
import * as entryModule from '/builds/reporting-n-viz/reporting-ui-framework/dashboards-core-service/src/index.tsx';
"__SPARKY_LIBS__.__MICRO_APPS__.dashboardscoreservice";
function run(name, genid, dev, module) {
  const curScript = window.document.currentScript ||
    (dev && window.document.querySelector(`script[data-micro-app='${name}']:not([src])`))
  if (curScript) {
    const { microAppId, generatedId } = curScript
    if ((dev && !curScript.hasAttribute('src') ||
      (generatedId && genid ? generatedId === genid : (microAppId && microAppId === name)))) {
      curScript.__ep = module
      curScript.dispatchEvent(new CustomEvent('micro-app-ep', { detail: module }))
      if (!dev) {
        return // only dev mode always has fallback
      }
    }
    else if (!dev && generatedId && genid) {
      console.error(`generated id mismatch for micro-app ${name}: ${generatedId} != ${genid}`)
      return
    }
  }
  const __MICRO_APPS__ = window.__SPARKY_LIBS__ && window.__SPARKY_LIBS__.__MICRO_APPS__
  if (!__MICRO_APPS__) {
    console.error(`unable to load micro-app ${name} due to cannot detect sparky framework and micro-app script`)
  }
  else {
    if (!curScript || !dev) {
      console.warn(`fallback to use __MICRO_APPS__.${name} to export micro-app`)
    }
    __MICRO_APPS__[name] = module
  }
};
run('dashboardscoreservice','dashboardscoreserviceuar3Iq7','',entryModule);